export async function useGeocode(encodedAddress: string) {
  const config = useRuntimeConfig();
  const apikey = config.public.googleApiKey;
  try {
    const response = await $fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${apikey}`);

    if (response.status === "ZERO_RESULTS") {
      return null;
    } else {
      return response.results[0].geometry.location;
    }
  } catch (e) {
    console.error("useReverseGeocode: error");
  }
}

export default async function useReverseGeocode(lng: number, lat: number) {
  const config = useRuntimeConfig();
  const apikey = config.public.googleApiKey;
  try {
    const response = await $fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&result_type=street_address&key=${apikey}`
    );

    if (response.status === "ZERO_RESULTS") {
      return null;
    } else {
      return response.results[0].formatted_address;
    }
  } catch (e) {
    console.error("useReverseGeocode: error");
  }
}
